<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12 pl-6 pt-6 pr-6>
                    <v-layout row wrap justify-end>
                        <v-menu v-model="menuDate" :close-on-content-click="false"  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                :disabled="storereport.ID > 0"
                                v-model="computedDateFormatted"
                                label="Ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="orderDate" no-title @input="menuDate = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md4 sm12 pl-4>
                    <h-currency-input v-model="storereport.IncomeAmount" :decimal="1" label="Thu (¥)" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg4 md4 sm12 pl-4>
                    <h-currency-input v-model="storereport.OutcomeAmount" :decimal="1" label="Chi (¥)" class="input-group--focused"></h-currency-input>
                </v-flex>
                 <v-flex lg4 md4 sm12 pl-4>
                    <v-text-field v-model="storereport.TransactionCode" label="Mã bill" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-textarea v-model="storereport.Reason" label="Lý do chi" class="input-group--focused"></v-textarea>
                </v-flex>                
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import configs from "@/configs";
export default {
    data() {
        return {
            menuDate: false,
            orderDate: moment.utc(Date.now()).toISOString().substr(0, 10)
        };
    },
    computed: {
        ...mapState({
            loading: state => state.storereport.loading,
            storereport: state => state.storereport.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        },
        computedDateFormatted () {
            return this.storereport.ReportDate && this.storereport.ID > 0 ? moment.utc(this.storereport.ReportDate).format(configs.SHORT_DATE_FORMAT) : moment.utc(this.orderDate).format(configs.SHORT_DATE_FORMAT);
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
    },
    watch: {
        modifyId() {
            this.refreshData();
        },
        storereport() {
            if(this.storereport && this.storereport.ID > 0 && this.storereport.ReportDate > 0) {
                this.orderDate = moment.utc(this.storereport.ReportDate).toISOString().substr(0, 10)
            } 
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("storereport/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("storereport/setDetail", { data: {
                        ID: 0,
                        ReportDate: moment.utc(Date.now())
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("storereport/setDetail", {
                data: {
                    ...this.storereport,
                    ReportDate: this.orderDate
                },
                id: this.storereport.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "StoreReportList");
        },
    },
    mounted() {
        if(this.paymentRole) {
            window.getApp.$emit("APP_NAVIGATE", "StoreReportList");
        }
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>